:root {
  /* Primary Colors */
  --primary-dark-blue: #00004d;
  --primary-red: #ed2024;
  --primary-light-red: #e21a1d;

  /* Secondary Colors */
  --secondary-dark-gray: #55636e;
  --secondary-light-gray: #d1dbe5;
  --secondary-off-white: #eef2f6;
  --secondary-white: #ffffff;

  /* Tertiary Colors */
  --tertiary-blue: #0c5bbf;
  --chakra-colors-chakra-body-bg: red;
}

.App {
  text-align: center;

  /* Typographic Styles */

  /* Inter Regular */
  .inter-regular {
    font-family: "Inter", sans-serif;
    font-weight: 400; /* Regular */
  }

  /* Inter Medium */
  .inter-medium {
    font-family: "Inter", sans-serif;
    font-weight: 500; /* Medium */
  }

  /* Inter Semi-Bold */
  .inter-semi-bold {
    font-family: "Inter", sans-serif;
    font-weight: 600; /* Semi-Bold */
  }

  /* Inter Bold */
  .inter-bold {
    font-family: "Inter", sans-serif;
    font-weight: 700; /* Bold */
  }

  /* Headings */

  /* H1 Heading */
  .h1-heading {
    font-family: "Inter", sans-serif;
    font-weight: 600; /* Semi-Bold */
    font-size: 60px;
    line-height: 140%;
  }

  /* H2 Heading */
  .h2-heading {
    font-family: "Inter", sans-serif;
    font-weight: 600; /* Semi-Bold */
    font-size: 48px;
    line-height: 140%;
  }

  /* H3 Heading */
  .h3-heading {
    font-family: "Inter", sans-serif;
    font-weight: 600; /* Semi-Bold */
    font-size: 36px;
    line-height: 140%;
  }

  /* H4 Heading */
  .h4-heading {
    font-family: "Inter", sans-serif;
    font-weight: 600; /* Semi-Bold */
    font-size: 30px;
    line-height: 140%;
  }

  /* Subheading 1 */
  .subheading1 {
    font-family: "Inter", sans-serif;
    font-weight: 400; /* Regular */
    font-size: 15px;
    line-height: 140%;
  }

  /* Body text */
  .body-text {
    font-family: "Inter", sans-serif;
    font-weight: 400; /* Regular */
    font-size: 16px;
    line-height: 140%;
  }

  /* Table content */
  .table-content {
    font-family: "Inter", sans-serif;
    font-weight: 500; /* Medium */
    font-size: 14px;
    line-height: 140%;
  }

  /* Secondary Menu */
  .secondary-menu {
    font-family: "Inter", sans-serif;
    font-weight: 600; /* Semi-Bold */
    font-size: 14px;
    line-height: 140%;
  }

  .chakra-collapse {
    overflow: visible !important;
  }

  /* Button styles
  .btn {
    padding: 16px 32px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    text-decoration: none;
    border: none;
    font-weight: 500;
  }

  .primary-btn {
    background-color: var(--primary-dark-blue);
    color: var(--secondary-white);
  }

  .primary-btn:hover {
    background-color: #333;
  }

  .secondary-btn {
    background-color: var(--primary-red);
    color: var(--secondary-white);
  }

  .secondary-btn:hover {
    background-color: #b2181a;
  }

  .tertiary-btn {
    background-color: var(--secondary-off-white);
    color: var(--secondary-dark-gray);
    border: 1px solid #bbb;
  }

  .tertiary-btn:hover {
    background-color: #ddd;
  } */
}

/* Sidebar Styles */

/* Base style for sidebar items */
.sidebar-item {
  color: var(--secondary-dark-gray);
  /* other base styles */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Hover state for sidebar items */
.sidebar-item:hover {
  background-color: var(--secondary-light-gray);
  /* other hover styles */
}

/* Active state for sidebar items */
.sidebar-item.active {
  background-color: var(
    --secondary-white
  ); /* This could be any color that indicates active state */
  color: var(
    --primary-dark-blue
  ); /* Change text color for active item if needed */
  /* other active styles */
}

/* Style for the active indicator (e.g., red line under 'DOCUMENTS') */
.active-indicator {
  height: 3px; /* Set the height for the indicator */
  background-color: var(
    --primary-red
  ); /* Using primary red for the active indicator */
  width: 100%; /* Adjust if necessary */
  /* other styles for positioning, if needed */
}

/* Style for the menu label (e.g., 'DOCUMENTS') */
.menu-label {
  text-transform: uppercase;
  font-weight: 600; /* Adjust according to your design */
  margin-bottom: 10px; /* Adjust according to your design */
  color: var(--secondary-dark-gray); /* Color for the menu labels */
  /* other label styles */
}

/* Style for the user info section at the top of the sidebar */
.user-info {
  /* styles for the user info container */
  color: var(--primary-dark-blue); /* Color for the user info text */
}

.user-info .name {
  font-weight: bold;
  /* other styles for name text */
}

.user-info .role {
  font-style: italic;
  /* other styles for role text */
}

/* Style for the dropdown menu when active */
.dropdown-menu {
  display: none; /* hidden by default */
  /* other styles for dropdown menu */
}

.dropdown-menu.active {
  display: block; /* show when active */
  /* additional styles for active state */
}

.chakra-stepper[data-orientation="horizontal"] {
  align-items: flex-start !important;
}

tr .css-wxrlk7[data-is-numeric="true"],
tr .css-6jodsw[data-is-numeric="true"] {
  text-align: center !important;
}

section {
  margin-block: 16px;
}

.custom-highlight {
  background-color: #3298fd;
  color: white;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.changing-text {
  background: linear-gradient(
    90deg,
    #ff2a2a,
    #a6149c,
    #3a36f4
  ); /* Adjust colors as needed */
  color: white;
  animation: gradientAnimation 3s infinite;
  background-size: 200% 100%;
}

::selection {
  background-color: #3298fd;
  color: white;
}

.ql-editor {
  /* height: 80vh; */
  font-size: 15pt !important;
  /* white-space: pre!important; */
  font-family: "Times New Roman", Times, serif;
  /* font-size: 12pt; */
  /* line-height: 1em; */
  border-radius: 12px;
}

.ql-editor p {
  margin-bottom: 1em !important;
}

/* Ensure last paragraph doesn't have extra margin */
.ql-editor p:last-child {
  margin-bottom: 0;
}

.ql-container {
  overflow: hidden !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.loading-text {
  background-color: #e0e0e0;
  display: inherit;
  height: auto;
  width: fit-content;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
.red {
  background-color: red;
  color: blue;
}

.image-uploading {
  position: relative;
  display: inline-block;
}

.image-uploading img {
  max-width: 98% !important;
  filter: blur(5px);
  opacity: 0.3;
}

.image-uploading::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #1e986c;
  z-index: 1;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.ql-snow .ql-picker {
  height: auto;
}

/* fonts */
.ql-font-times-new-roman {
  font-family: "Times New Roman", Times, serif;
}
.ql-font-garamond {
  font-family: "Garamond", serif;
}
.ql-font-georgia {
  font-family: "Georgia", serif;
}
.ql-font-cambria {
  font-family: "Cambria", serif;
}
.ql-font-century-schoolbook {
  font-family: "Century Schoolbook", serif;
}
.ql-font-arial {
  font-family: "Arial", sans-serif;
}
.ql-font-verdana {
  font-family: "Verdana", sans-serif;
}
.ql-font-helvetica {
  font-family: "Helvetica", sans-serif;
}
.ql-font-book-antiqua {
  font-family: "Book Antiqua", serif;
}

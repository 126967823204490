.document_section > :first-child {
  width: 100%;
}

.section {
  margin-top: 20px;
}

.document_section > :nth-child(n + 2) {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.document_section {
  flex-direction: column;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .section {
    margin-top: 0px;
  }

  .document_section {
    flex-direction: row;
  }

  .document_section > :first-child {
    width: 70%;
  }

  .document_section > :nth-child(n + 2) {
    width: 30%;
    justify-content: center;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  gap: 30px;
}

.container {
  margin-top: 60px;
}

@media screen and (min-width: 744px) {
}

@media screen and (min-width: 1024px) {
  .content {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }

  .container {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1300px) {
}

@media screen and (min-width: 1600px) {
}

.header {
  /* padding: 8px 20px; */
  height: 60px;
  visibility: visible;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

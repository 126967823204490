.sidebar {
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100vh);
  z-index: 2 !important;
  border-radius: 0 30px 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: left ease 0.5s;
  transition: all 1s ease;
  padding-inline: 16px;
  &.hide {
    left: -100%;
  }

  &.show {
    left: 0;
  }


}
.menu_title {
  font-weight: 500;
  font-size: 16px;
}

.logo {
  display: none;
  position: relative;
}

.menu_panel {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
  }

  li {
    list-style: none;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    color: var(--secondary-color);

    &.active {
      background-image: linear-gradient(
        94.62deg,
        #15abd0 -18.03%,
        #0a5566 120.97%
      );
      color: #fff;
      opacity: 0.7;

      a,
      .menu_icon {
        color: #fff;
      }
    }

    &.deactive {
      background-image: #fff;
    }

    &:hover {
      opacity: 75%;
      transition: all 300ms ease;
    }
    &.active {
      opacity: 1;
    }
  }
}

.flag {
  height: 14px;
  width: auto;
}

.menu_icon {
  color: var(--link-color);
  margin-right: 10px;
  width: 20px;
  transition: all 300ms ease;

  &:hover {
    color: var(--link-hover);
  }
}

@media screen and (min-width: 744px) {
}

@media screen and (min-width: 1024px) {
  .sidebar {
    position: static;
    height: auto;
    border-radius: 0;
  
    .profile {
      display: none;
    }
  }

  .logo {
    display: block;
    margin: 15px 0;
  }

  .logout_button {
    visibility: hidden;
  }
}


.hamburger_menu {
  width: 100%;
  background: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(5px);
  transition: all 1s ease;
  border-bottom: 1px solid #ece4e4;
}

@media screen and (min-width: 744px) {
}

@media screen and (min-width: 1024px) {
  .hamburger_menu {
    display: none;
  }
}

@media screen and (min-width: 1300px) {
}

@media screen and (min-width: 1600px) {
}

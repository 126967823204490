@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.steps_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.step_box {
  height: 150px;
  min-height: 220px;
  width: 100%;
  font-size: 22px;
  box-sizing: border-box; /* Ensure padding and borders are included in the width */
}

@media screen and (min-width: 1024px) {
  .steps_container {
    padding: 20px;
    flex-direction: row;
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
    justify-content: flex-start; /* Adjust if you want the boxes to align differently */
    gap: 20px; /* Maintain gap if needed */
  }

  .step_box {
    /* Adjust width so they can sit side by side, aiming for six per row */
    width: calc(16.666% - (20px * 5 / 6)); /* Subtract a portion of the gap from each box */
    font-size: 18px;
    /* No change to height or min-height needed here unless you want them to be different from the mobile layout */
  }
}

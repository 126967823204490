.tabs_container {
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 744px) {
}

@media screen and (min-width: 1024px) {
  .tabs_container {
    flex-direction: column;
  }
}

@media screen and (min-width: 1300px) {
}

@media screen and (min-width: 1600px) {
}
